const config = {
	name: 'joubertsaquett',
	title: "Hi I'm Joubert 👋🏻",
	blog: 'Dev', // Dev.to or Medium (Make blank if you don't need the blog)

	// Social media profile username
	github: 'joubertsaquett', // [REQUIRED] Github Username
	twitter: '', // Twitter Username
	stackoverflow: '', // Eg: 3211944
	medium: '', // without @ symbol
	instagram: '_joubert.saquett',
	dev: '', //pjijin
	youtube: 'UCZ0BBn3FLZ3mq4Uce18jgsQ', // Channel ID
	linkedin: 'joubert-saquett',
	whatsapp: '11972893490'
};

export { config };
