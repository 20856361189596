import React from 'react';

const About = ({ name }) => {
	return (
		<section className="about" id="about">
			<h3>
				Olá! Eu sou Joubert Saquett{/*name*/} <i className="animated hover-rotate em em-raised_hand_with_fingers_splayed" />
			</h3>
			<div className="details">
				<div className="item text-muted">
					<p>
						Formado em Gestão de TI(2016) e com MBA em Gestão da Qualidade de TI(2022), tenho participado de projetos que impactam a vida de milhões de pessoas tanto na área de técnologia quanto BI (Business intelligence).
						<br />
						Tenho como foco em liderar times de técnologia, além de atuar no desenvolvimento aplicações back-end escaláveis e realizar análises de dados com BI.
						<br />
						<br />
						Gosto de ler sobre finanças, inteligência emocional, investimentos, mindset e assuntos relacionados a crescimento, além de estudar sobre assuntos derivados de técnologia.
						<br />
						<br />
						Skills:
						<br />
						* Data Viz com Tableau, Power BI e Data Studio para amostragem de dados e análises de negócio.
						<br />
						* AWS + Serverless: Para criar aplicações escaláveis e sustentáveis em microserviços.
						<br />
						* Tenho experiência em programação back-end com JavaScript, Python e PHP.
						<br />
						* Banco de dados SQL e NoSql como (MySQL, SQLServer, Postgre, Orace, SQLite, MongoDB, DynamoDB ).
						<br />
						* Front-end, tenho conhecimento de diversas técnologias, mas não é meu foco no momento.

						<br />
						<br />
						Alguns clientes que já atendi são: Vivo, Tim, Ambev, Globo, Veloe, Volvo, Puma, ResMed, Oswaldo Cruz, UnigranRio, Swariovski, Divena e GPI.
						<br />
						<br />
						<br />
						{/* <i className="animated hover-bounce em-svg em-heart f-12" /> */}
					</p>
					<p className='homenagem'>Tudo que conquistei e vou conquitar devo a meu pai, Jorge Saquett (19/09/1973 - 19/09/2022).</p>
					{/* <p>
						Some of my previous works:
						<a rel="noopener noreferrer" target="_BLANK" href="https://betternode.com">
							Betternode
						</a>
						,
						<a rel="noopener noreferrer" target="_BLANK" href="https://cryptobeanie.com">
							CryptoBeanie
						</a>
						,
						<a rel="noopener noreferrer" target="_BLANK" href="https://clixwall.com">
							ClixWall
						</a>
					</p> */}
				</div>
			</div>
		</section>
	);
};

export { About };
